<template>
  <div>
<!--    <div class="title flexB">
      <h1>{{ $t("site-view-contents-title") }}</h1>
      <div>
         <span>
&lt;!&ndash;           {{ moment().format("YYYY.MM.DD(dd) HH:mm") }} {{ $t("data-today") }}&ndash;&gt;
         </span>
        <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>
      </div>
    </div>
    <div class="title siteInfo">
      <span class="bold">No.{{ event.eventId }}</span>
      <span class="bold">{{ event.title }}</span>
      <span>{{ event.address + " " + event.addressDetail }}</span>
      <span> {{ department ? department.name : "-" }}</span>
      <span>{{ event.managerName }}</span>
      <span>{{
          event.managerPhone
              ? event.managerPhone.slice(0, 3) +
              "-" +
              event.managerPhone.slice(3, 7) +
              "-" +
              event.managerPhone.slice(7, 11)
              : "-"
        }}</span>
      <span>{{ event.managerEmail ? event.managerEmail : "-" }}</span>
    </div>-->
    <div class="contents liveInfo" style="padding: 0;">
      <div class="flexB" style="margin-bottom: 0px;">
        <ul class="flex liveTab">
          <li :class="{ active: visible == 1 ? true : false }">
            <a @click="handleTab(1)">Member</a>
          </li>

          <div class="last_line"></div>
        </ul>
      </div>

      <Member v-if="visible == 1" :eventId="id" />

    </div>
  </div>
</template>
<script>

import { fetchEvent } from "@/api/event";

import Member from "./contents/Recode_Member";

export default {
  components: {  Member, },
  name: "LiveInfo",
  data() {
    return {
      visible: null,
      editMode: false,
      event: {},
      id: "",
      department: {},
    };
  },
  created() {
    this.id = this.$route.query.eventId;
    this.$store.dispatch("store/SET_NAVBAR", { nav: 2, sub: 0 });
  },
  mounted() {
    this.getEventDetail();
  },
  methods: {
    preset(){
      let id = localStorage.getItem("LiveEventInfoSub");
      if(id != undefined) {
        this.visible = id;
      } else {
        if(this.visible == null){
          this.visible = 1;
        }
        localStorage.setItem("LiveEventInfoSub", this.visible);
      }
    },
    goVOD() {
      this.visible = 4;
    },
    handleRefresh() {
      this.$router.go();
    },
    handleTab(id) {
      this.visible = id;
      localStorage.setItem("LiveEventInfoSub", this.visible);
    },
    getEventDetail() {
      if(this.id == undefined) {
        return;
      }
      fetchEvent(this.id).then((res) => {
        this.event = res.data.data;
        this.department = res.data.data.managerDepartment;
        this.preset();
      });
    },
  },
};
</script>
