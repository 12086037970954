<template>
  <div>
    <div class="title flexB">
      <h1>{{ $t("site-view-monitoring-title") }}</h1>
      <div class="title siteInfo" style="padding-right: 0;">
        <div class="sitebox">
          <span class="bold">No.{{ event.viewId != null ? event.viewId : event.eventId }}</span>
          <span class="bold">{{ event.title }}</span>
        </div>
      </div>
    </div>
    <div class="contents liveInfo">
      <div class="flexB">
        <ul class="flex controlTab">
          <li :class="{ active: visible == 1 ? true : false }">
            <a @click="handleTab(1)" style="font-size: 1.6rem;">Monitoring</a>
          </li>
          <li :class="{ active: visible == 2 ? true : false }">
            <a @click="handleTab(2)" style="font-size: 1.6rem;">Contents</a>
          </li>
          <li :class="{ active: visible == 3 ? true : false }">
            <a @click="handleTab(3)" style="font-size: 1.6rem;">Device</a>
          </li>
          <li :class="{ active: visible == 4 ? true : false }">
            <a @click="handleTab(4)" style="font-size: 1.6rem;">Member</a>
          </li>
          <li :class="{ active: visible == 5 ? true : false }">
            <a @click="handleTab(5)" style="font-size: 1.6rem;">Mission Flight</a>
          </li>
        </ul>
      </div>
      <LiveInfoMonitoring v-if="visible == 1" :eventId="id" :address="event.address" @goHome="goHome" />
      <LiveInfoContents v-if="visible == 2" :eventId="id" :address="event.address" />
      <LiveInfoDevice v-if="visible == 3" :eventId="id" :address="event.address" />
      <LiveInfoMember v-if="visible == 4" :eventId="id" :address="event.address" />
      <LiveInfoMissionFlight v-if="visible == 5" :eventId="id" :address="event.address" />
    </div>
  </div>
</template>
<style>
  .controlTab li{
    position: relative;
  }
  .controlTab li a{
    padding: 3px 30px;
    height: 26px;
    color: #000;
    border: 1px solid #b9b9b9;
    border-radius: 13px;
    background: white;
    text-align: center;
    margin-right: 16px;
  }
  .controlTab li.active a{
    background: #606060;
    border-radius: 13px;
    border: none;
    color: white;
  }
</style>
<script>
import { fetchEvent } from "@/api/event";

import LiveInfoContents from "./LiveInfoContents";
import LiveInfoMonitoring from "./LiveInfoMonitoring";
import LiveInfoMember from "./LiveInfoMember";
import LiveInfoDevice from "./LiveInfoDevice";
import LiveInfoMissionFlight from "./LiveInfoMission";

export default {
  components: { LiveInfoMissionFlight, LiveInfoMonitoring, LiveInfoContents, LiveInfoMember, LiveInfoDevice, },
  name: "LiveInfoControl",
  data() {
    return {
      visible: null,
      editMode: false,
      event: {},
      id: null,
      department: {},
    };
  },
  created() {
    this.id = this.$route.query.eventId;
    this.$store.dispatch("store/SET_NAVBAR", { nav: 2, sub: 0 });
  },
  mounted() {
    this.getEventDetail();
  },
  methods: {
    preset(){
      let id = localStorage.getItem("LiveEventInfoMain");
      if(id != undefined) {
        this.visible = id;
      } else {
        if(this.visible == null){
          this.visible = 1;
        }
        localStorage.setItem("LiveEventInfoMain", this.visible);
      }
    },
    goHome() {
      this.visible = 1;
    },
    handleRefresh() {
      this.$router.go();
    },
    handleTab(id) {
      this.visible = id;
      localStorage.setItem("LiveEventInfoMain", this.visible);
      localStorage.removeItem("LiveEventInfoSub");
    },
    getEventDetail() {
      if(this.id == undefined) {
        return;
      }
      fetchEvent(this.id).then((res) => {
        this.event = res.data.data;
        this.department = res.data.data.managerDepartment;
        if(!this.$store.state.store.isAdmin && this.event.managerDepartment.departmentId != this.$store.state.store.departmentId){
          var filter = this.event.departmentList.filter(item => item.departmentId == this.$store.state.store.departmentId);
          if(this.event.isAuth == true && filter.length == 0) {
            alert(this.$t("user-not-permission"));
            this.$router.push({path:"liveListControl"});
          }
        }
        this.preset();
      });
    },
  },

};
</script>
