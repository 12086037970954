<template>
  <div class="contents liveInfo">
    <div class="flex">
    <MissionList v-if="visible == 1" :eventId = "eventId" @select-mission="handleDetail"/>
    </div>
    <MissionDetail v-if="visible == 2" :eventId = "eventId"  :waypointId="waypointId" @back-list="backToMissionList" />
  </div>
</template>

<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";

import MissionDetail from "./MissionDetail";
import MissionList from "./MissionList";

export default {
  components: {  MissionDetail, MissionList },
  name: "MissionView",
  props: ["eventId"],
  data() {
    return {
      ko: ko,
      moment: moment,
      visible: 1,
      total: 0,
      size: 10,
      currentPage: 0,
      keywordType: "all",
      keyword: null,
      waypointId : null,
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 3 });
  },
  mounted() {
  },
  methods: {
    handleDetail(id) {
      this.waypointId = id;
      this.visible = 2;
    },
    backToMissionList() {
      this.visible = 1;
    },
    handleRefresh() {
      this.$router.go();
    },
  },
};
</script>
