<template>
  <div>
    <div class="contents liveInfo" style="padding: 0;">
      <div class="flexB" style="margin-bottom: 0px;">
<!--        <ul class="flex liveTab">
          <li :class="{ active: siteVisible == 1 ? true : false }">
            <a @click="handleTab(1)">VOD</a>
          </li>
          <li :class="{ active: siteVisible == 2 ? true : false }">
            <a @click="handleTab(2)">Point</a>
          </li>
          <li :class="{ active: siteVisible == 3 ? true : false }">
            <a @click="handleTab(3)">Notice</a>
          </li>
          <li :class="{ active: siteVisible == 4 ? true : false }">
            <a @click="handleTab(4)">Mission VOD</a>
          </li>
          <li :class="{ active: siteVisible == 5 ? true : false }">
            <a @click="handleTab(5)">Live Map</a>
          </li>

          <div class="last_line"></div>
        </ul>-->
      </div>

      <Vod v-if="siteVisible == 1" :eventId="id" :address="event.address"  @goHome="goVOD" />
      <Point v-if="siteVisible == 2" :eventId="id" />
      <Notice v-if="siteVisible == 3" :eventId="id" />
      <MissionVod v-if="siteVisible == 4" :eventId="id" />
      <LiveMap v-if="siteVisible == 5" :eventId="id" />

    </div>
  </div>
</template>
<script>

import { fetchEvent } from "@/api/event";

import Vod from "./contents/Vod";
import MissionVod from "./contents/Mission_Vod";
import LiveMap from "./monitoring/liveMap/LiveMapView";
import Notice from "./monitoring/Notice";
import Point from "./contents/Recode_Point";
import {mapState} from "vuex";

export default {
  components: {  Vod, MissionVod, LiveMap, Notice, Point },
  name: "LiveInfo",
  computed: {
    ...mapState({
      siteVisible: state => state.store.siteVisible,
    }),
  },
  data() {
    return {
      editMode: false,
      event: {},
      id: "",
      department: {},
    };
  },
  created() {
    this.id = this.$route.query.eventId;
    this.$store.dispatch("store/SET_NAVBAR", { nav: 2, sub: 0 });
  },
  mounted() {
    this.getEventDetail();
  },
  methods: {
    preset(){
      let id = localStorage.getItem("LiveEventInfoSub");
      if(id != undefined) {
        this.siteVisible = id;
      } else {
        if(this.siteVisible == null){
          this.siteVisible = 1;
        }
        localStorage.setItem("LiveEventInfoSub", this.siteVisible);
      }
    },
    goVOD() {
      this.siteVisible = 4;
    },
    handleRefresh() {
      this.$router.go();
    },
    handleTab(id) {
      this.siteVisible = id;
      localStorage.setItem("LiveEventInfoSub", this.siteVisible);
    },
    getEventDetail() {
      if(this.id == undefined) {
        return;
      }
      fetchEvent(this.id).then((res) => {
        this.event = res.data.data;
        this.department = res.data.data.managerDepartment;
        this.preset();
      });
    },
  },
};
</script>
